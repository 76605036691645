import React ,  { Component } from "react";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

class VideoProduct extends Component {
    render(){
        return(
            <div>
                {/* Header Navbar */}
                <Navbar />
                {/* Breadcaump Area */}
                <div className="breadcaump-area ptb--120 bg_image bg_image--1" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title">Video Produktion</h1>
                                    <ul className="page-breadcrumb">
                                        <li><a href="/">Home</a></li>
                                        <li>-</li>
                                        <li>Video Produktion</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Blog Details */}
                <div className="blog-details pt--120 pt_md--80 pt_sm--80 bg-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-inner">
                                    <article className="single-post-details">
                                            
                                        <div className="row">
                                            <div className="col-md-6">    
                                                <div className="post-thumbnail height100">
                                                        <img className="w-100" src="/assets/images/video-edit.jpg" alt="Deisng und Konzept Mediaface" />
                                                    </div>
                                                </div>
                                            <div className="col-md-6">
                                                <h2 className="first-bold">Ob YouTube-Video, Imagefilm oder Produktvideo</h2>
                                                <p className="first-bold">
                                                Ob YouTube-Video, Imagefilm oder Produktvideo, wir sorgen dafür, dass Sie Ihre Zielgruppe effektiv über Video erreichen.
                                                Abgestimmt auf Ihre Ziele, kreieren wir hochwertigen Video-Content, der eine überzeugende Geschichte erzählt und produzieren 
                                                Werbefilme sowie redaktionellen Video Content für alle Kanäle.
                                                Gemeinsam mit Ihnen treffen wir die strategische Entscheidung, welchen Kanälen und Plattformen Sie Ihre Zielgruppe bestmöglich 
                                                erreichen und welcher Content für diese relevant ist. 
                                                </p>
                                            </div>
                                        </div>
                                        <div className="content mt--50">
                                            <blockquote className="quote-content">
                                                <div className="quote-text">
                                                Egal, ob es um Video- oder Filmproduktion geht, wir arbeiten mit den richtigen Experten, die Ihrem Video den letzten Schliff verleihen und begleiten Sie von der ersten Idee bis zum fertigen Video und rücken Sie, Ihr Unternehmen oder Ihre Produkte ins Rampenlicht.
                                                </div>
                                            </blockquote>
                                        </div>
                                        <div className="post-thumbnail mt--50">
                                                    <img className="w-100" src="/assets/images/video-camera-light.jpg" alt="Blog Images" />
                                                </div>
                                        <div className="content mt--50">
                                            <h2>überzeugte Markenfans schaffen</h2>
                                            <p className="first-bold mt--35">
                                            Egal, ob Sie überzeugte Markenfans schaffen, Kunden die Anwendung eines Produktes erklären oder zukünftige Mitarbeiter für Ihr Unternehmen begeistern wollen, wir kreieren einen optimalen Content Plan und sorgen dafür, dass Ihr Video sichtbar ist und den Kampf um Aufmerksamkeit für sich entscheidet.
                                                Durch die Definition geeigneter Messgrößen analysieren wir Ihren Erfolg und stellen sicher, dass Sie Ihre Ziele erreichen. 
                                                </p>
                                        </div>
                                        <div className="blog-footer mt--50">
                                            <div className="row align-items-center">
                                                <div className="col-lg-6 col-sm-6 col-12">
                                                    {/*<div className="post-tag d-flex align-items-center">
                                                        <h6 className="font--16 mb--0">Tags:</h6>
                                                        <div className="blog-tag-list pl--5">
                                                            <a href="/">App Landing</a>
                                                            <a href="/">App</a>
                                                            <a href="/">App Landing</a>
                                                        </div>
                                                    </div>*/}
                                                </div>
                                                <div className="col-lg-6 col-sm-6 col-12 mt_mobile--20">
                                                    <div className="post-share justify-content-start justify-content-sm-end d-flex align-items-center">
                                                        <h6 className="font--16 mb--0">Share:</h6>
                                                        <div className="author-social">
                                                            <a className="facebook" href="/"><i className="zmdi zmdi-facebook"></i></a>
                                                            <a className="twitter" href="/"><i className="zmdi zmdi-twitter"></i></a>
                                                            <a className="google-plus" href="/"><i className="zmdi zmdi-google-plus"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>

                                    {/*<div className="comments-wrapper mt--50">
                                        <div className="inner">
                                            <h4 className="font--24">3 Comments</h4>
                                            <div className="commnent-list-wrap mt--25">
                                            {/* Start Single Comment */}
                                            {/*<div className="comment">
                                                <div className="thumb">
                                                    <img src="/assets/images/blog/team-01.jpg" alt="Multipurpose" />
                                                </div>
                                                <div className="content">
                                                    <div className="info d-flex justify-content-between">
                                                        <h6 className="mb--0">Fatema Asrafi</h6>
                                                        <span className="reply-btn"><a href="/">Reply</a></span>
                                                    </div>
                                                    <div className="comment-footer mt--5">
                                                        <span>May 17, 2018 at 1:59 am</span>
                                                    </div>
                                                    <div className="text mt--5 pr--50 pr_sm--5">
                                                        <p className="bk_pra">To link your Facebook and Twitter accounts,
                                                            open the Instagram app on your phone or tablet, and select
                                                            the Profile tab in the bottom-right corner of the screen.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Comment */}
                                                
                                            {/* Start Single Comment */}
                                            {/*<div className="comment comment-reply">
                                                <div className="thumb">
                                                    <img src="/assets/images/blog/team-02.jpg" alt="Multipurpose" />
                                                </div>
                                                <div className="content">
                                                    <div className="info d-flex justify-content-between">
                                                        <h6 className="mb--0">John Doe</h6>
                                                        <span className="reply-btn"><a href="/">Reply</a></span>
                                                    </div>
                                                    <div className="comment-footer mt--5">
                                                        <span>May 17, 2018 at 1:59 am</span>
                                                    </div>
                                                    <div className="text mt--5 pr--50 pr_sm--5">
                                                        <p className="bk_pra">To link your Facebook and Twitter accounts,
                                                            open the Instagram app on your phone or tablet, and select
                                                            the Profile tab in the bottom-right corner of the screen.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Comment */}
                                                
                                            {/* Start Single Comment */}
                                            {/*<div className="comment">
                                                <div className="thumb">
                                                    <img src="/assets/images/blog/team-03.jpg" alt="Multipurpose" />
                                                </div>
                                                <div className="content">
                                                    <div className="info d-flex justify-content-between">
                                                        <h6 className="mb--0">Jane Ara</h6>
                                                        <span className="reply-btn"><a href="/">Reply</a></span>
                                                    </div>
                                                    <div className="comment-footer mt--5">
                                                        <span>May 17, 2018 at 1:59 am</span>
                                                    </div>
                                                    <div className="text mt--5 pr--50 pr_sm--5">
                                                        <p className="bk_pra">To link your Facebook and Twitter accounts,
                                                            open the Instagram app on your phone or tablet, and select
                                                            the Profile tab in the bottom-right corner of the screen.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Comment */}
                                            {/*</div>
                                        </div>
                                    </div>*/}
                                    {/* Start Comment Form */}
                                    {/*<div className="comment-form-wrapper mt--50">
                                        <div className="header">
                                            <h4 className="font--24">Leave A Comment</h4>
                                            <div className="coppent-note mt--20 mb--30">
                                                <p className="bk_pra">Your email address will not be published. Required fields
                                                    are marked </p>
                                            </div>
                                        </div>
                                        <div className="contact-form">
                                            <form action="/">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <input type="text" placeholder="Name *" />
                                                    </div>
                                                    <div className="col-lg-12 mt--30">
                                                        <input type="text" placeholder="Email *" />
                                                    </div>
                                                    <div className="col-lg-12 mt--30">
                                                        <input type="text" placeholder="Website" />
                                                    </div>
                                                    <div className="col-lg-12 mt--30">
                                                        <textarea placeholder="Your Comment"></textarea>
                                                    </div>
                                                    <div className="col-lg-12 mt--30">
                                                        <div className="blog-btn">
                                                            <a href="/">Post Comment</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* End Comment Form */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default VideoProduct;














