import React, { Component } from "react";
import SmokeVideo from '../../public/assets/videos/smoke.mp4';

class HeroSection extends Component {
  render(){
    return(
      <div className='hero-container'>
        <img src='/assets/images/logo/logo-invert.png' className="heroLogo" alt="logo" />
        <video src={SmokeVideo} autoPlay loop muted />
      </div>
    );
  }
}

export default HeroSection;
