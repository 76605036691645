import React, { Component } from "react";
import { Parallax } from 'react-scroll-parallax';
import iPhone from '../../public/assets/images/phone.png';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

class HeroOlive extends Component {
    render(){
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home" data-bg-color="#1e1e1e">
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-7 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    <h1>MEDIAFACE <br/> <span className="theme-color">WEB & MOBILE APP</span> PROGRAMMIERUNG</h1>
                                    <p>Wir verwirklichen Ihre Web- oder App-Idee responsiv, suchmaschinen- <br/> optimiert und benutzerfreundlich sowie Cross-Browser-kompatibel.</p>
                                    <div className="banner-buttons">
                                        <a href="#support"><button type="button" className="button-default button-olive">Kontakt</button></a>
                                        <a className="button-default button-white" href="#features" role="button">Leistungen</a>
                                    </div>
                                </div>
                            </div>

                            <div className="banner-apps">
                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-apple"></i>
                                        <h4>IOS</h4>
                                    </div>
                                </div>

                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                    <i className="zmdi zmdi-android"></i>
                                        <h4>Android</h4>
                                    </div>
                                </div>

                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                <i className="zmdi zmdi-windows"></i>
                                        <h4>Windows</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-product-image text-right">
                                <Parallax className="custom-class" y={[-30, 0]} tagOuter="figure">
                                    <img className="image-1" src={iPhone} alt="App Landing"/>
                                </Parallax>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeroOlive;








