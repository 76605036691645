import React, { Component } from "react";


class Screenshot extends Component { 
    render(){
        return (
            <div className="screenshot-area pt--120 mb--100" id="screenshots">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--80">
                                <h2>APP <span className="theme-color">SCREENSHOTS</span></h2>
                                <img className="image-1" src="/assets/images/app/title-icon-2.png" alt="App Landing"/>
                                <p>Das Design ist Teil Ihrer Corporate Identity und wir stellen sicher, dass es Ihre Idee und Ihre Kernwerte optimal transportiert.</p>
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1">
                            <div className="screenshot-carousel" id="screenshot-carousel" data-carousel-3d>
                                <img className="image-1" src="/assets/images/screenshot1.png" alt="App Screenshot"/>
                                <img className="image-1" src="/assets/images/screenshot2.png" alt="App Screenshot"/>
                                <img className="image-1" src="/assets/images/screenshot3.png" alt="App Screenshot"/>
                                <img className="image-1" src="/assets/images/screenshot4.png" alt="App Screenshot"/>
                                <img className="image-1" src="/assets/images/screenshot1.png" alt="App Screenshot"/>
                                <img className="image-1" src="/assets/images/screenshot2.png" alt="App Screenshot"/>
                                <img className="image-1" src="/assets/images/screenshot3.png" alt="App Screenshot"/>
                                <img className="image-1" src="/assets/images/screenshot4.png" alt="App Screenshot"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Screenshot;










