import React ,  { Component } from "react";

class Navbar extends Component {
    render(){
        return(
            <div className="app-header header--transparent sticker" id="main-menu">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-sm-5 col-5">
                            <div className="logo">
                                <a href="/">
                                    <img className="header-logo" src="/assets/images/logo/icon-white.png" alt="app landing"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-8 d-none d-lg-block">
                            <div className="mainmenu-wrapper">
                                <nav>
                                    <ul className="main-menu">
                                        <li className="active"><a href="/">Home</a></li>
                                        <li><a href="/#features">Leistungen</a></li>
                                        <li><a href="/#team">Team</a></li>
                                    </ul>
                                </nav>
                                <button className="button-default button-olive" type="button">
                                    <a href="/#support">Kontakt</a>
                                </button>
                            </div>
                        </div>
                        <div className="col-sm-7 col-7 d-block d-lg-none">
                            <div className="mobile-menu">
                                <nav>
                                    <ul>                              
                                        <li className="active"><a href="/">Home</a></li>
                                        <li><a href="/#features">Leistungen</a></li>
                                        <li><a href="/#team">Team</a></li>
                                        <a href="/#support">
                                            <button className="button-default button-olive" type="button">Kontakt</button>
                                        </a>
                                    </ul>
                                    
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;


