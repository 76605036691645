import React, { Component } from "react";
import Navbar from './components/Navbar';
import HeroOlive from './components/HeroOlive';
import Feature from './components/Feature';
import Download from './components/Download';
import Screenshot from './components/Screenshot';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection'
import Team from "./components/team";

class HomeOlive extends Component{
    render(){
        return(
            <div>
                {/* Header Navbar */}
                <Navbar />

                <HeroSection></HeroSection>

                {/* Slider */}
                <HeroOlive bgshape="bg-shape" horizontal="" />

                {/* Screenshot */}
                <Screenshot />
                
                {/* Download */}
                <Download horizontal="" />
                
                {/* Team 
                <Team horizontal="" />
                */}
                
                {/*Feature */}
                <Feature horizontalfeature="vertical-feature" />
                
                {/* Footer */}
                <Footer horizontal="vertical-footer" />

            </div>
        )
    }
}

export default HomeOlive;

