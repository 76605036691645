import React ,  { Component } from "react";

import { Link } from 'react-router-dom';

class Feature extends Component{
    render(){
       let data = [
           
        {
            src: '/assets/images/Icons/brainstorm.svg',
            href: '/designkonzept',
            icon: 'zmdi zmdi-collection-text',
            title: 'Design und Konzept',
            desc: 'Wir kümmern uns um die Konzeption und das Design Ihrer Kampagne und stehen Ihnen von der Konzeption bis zur Umsetzung von on- und offline Gestaltungslösungen mit Kreativität und Professionalität zur Seite.'
        },

        {
            src: '/assets/images/Icons/app.svg',
            href: '/webapp',
            icon: 'zmdi zmdi-code-smartphone',
            title: 'Web und App Programmierung',
            desc: 'Das Webdesign ist Teil Ihrer Corporate Identity und wir stellen sicher, dass es Ihre Firmenkultur und Ihre Kernwerte optimal transportiert. Modernisieren Sie Ihren Auftritt und erweitern Sie Ihre Reichweite mit einer individuell erstellten App!'
        },

        {
            src: '/assets/images/Icons/print.svg',
            href: '/grafiktexte',
            icon: 'zmdi zmdi-brush',
            title: 'Grafik und Texte',
            desc: 'Überzeugende Grafiken und die richtigen Worte sind das Herzstück jeder Werbekampagne.'
        },

        {
            src: '/assets/images/Icons/social-media.svg',
            href: '/onlinemarketing',
            icon: 'zmdi zmdi-comment',
            title: 'Online Marketing',
            desc: 'Online Marketing ist dynamischer denn je und heute aus einer erfolgreichen Kampagne nicht mehr wegzudenken. Bei der Vielzahl an neuen Möglichkeiten bleibt bei wirkungsvollen Kampagnen eines im Mittelpunkt: der Kundennutzen.'
        },

        {
            src: '/assets/images/Icons/video-prod.svg',
            href: '/videoproduct',
            icon: 'zmdi zmdi-desktop-mac',
            title: 'Video Produktion',
            desc: 'Abgestimmt auf Ihre Ziele, kreieren wir hochwertigen Video-Content, der eine überzeugende Geschichte erzählt und produzieren Werbefilme sowie redaktionellen Video Content für alle Kanäle.'
        }
       ] 

       let DataList = data.map((val , i) => {
           return(
               /* Start Single Feature */
               <div className="feature" key={i}>
                   <Link to={`${val.href}`}>
                    <div className="feature-icon">
                        {/*<img src={`${val.src}`}/>*/}
                        <i className={`${val.icon}`}></i>
                    </div>
                    <div className="content">
                        <h4 className="title">{val.title}</h4>
                        <p className="desc">{val.desc}</p>
                    </div>
                   </Link>
               </div>
               /* End Single Feature */
           )
       })

       return(
           <div className= {`feature-area feature-bg-image pb--50 mt--100 ${this.props.horizontalfeature}`} id="features">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>Unsere <span className="theme-color">LEISTUNGEN</span></h2>
                                <img className="image-1" src="/assets/images/app/title-icon-2.png" alt="App Landing"/>
                                <p>Für den ersten Eindruck keine zweite Chance. Überzeugendes Design, und Übersichtlichkeit stehen bei uns im Vordergrund, damit schon der erste Kontakt Begeisterung weckt.</p>
                            </div>
                       </div>
                   </div>
                   <div className="row mt--30">
                       <div className="col-lg-7 offset-lg-5">
                            <div className="feature-list">
                                {DataList}
                            </div>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default Feature;
