import React ,  { Component } from "react";
import { Link } from 'react-router-dom';
import Logo from '../../public/assets/images/logo/mediaface-icon.png';
import ImgMarc from '../../public/assets/images/marc.jpg';

class Team extends Component{
    render(){

       return(
        <div>
            <div class="container">
                <div className="section-title text-center mb--40">
                    <h2>Unser <span className="theme-color">TEAM</span></h2>
                    <img className="image-1" src="/assets/images/app/title-icon-2.png" alt="App Landing"/>
                </div>
            </div>
            <div class="our-team-section justify-content-center">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="our-team">
                                <div class="pic">
                                    <img src="https://i.ibb.co/8x9xK4H/team.jpg"/>
                                </div>
                                <div class="team-content">
                                    <h3 class="title">Marc Ettl</h3>
                                    <span class="post">Dipl. FW Mediendesign & Medieninformatik</span>
                                </div>
                                <ul class="social">
                                    <li>
                                    <a href="#"  target="_blank"class="fa fa-facebook"></a>
                                    </li>
                                    <li>
                                    <a href="#" class="fa fa-twitter"></a>
                                    </li>
                                    <li>
                                    <a href="#" class="fa fa-google-plus"></a>
                                    </li>
                                    <li>
                                    <a href="#" class="fa fa-linkedin"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="our-team">
                                <div class="pic">
                                    <img src="https://i.ibb.co/8x9xK4H/team.jpg"/>
                                </div>
                                <div class="team-content">
                                    <h3 class="title">Gerhard Sinella</h3>
                                    <span class="post">Designer &<br/> Entwickler</span>
                                </div>
                                <ul class="social">
                                    <li>
                                    <a href="#" class="fa fa-facebook"></a>
                                    </li>
                                    <li>
                                    <a href="#" class="fa fa-twitter"></a>
                                    </li>
                                    <li>
                                    <a href="#" class="fa fa-google-plus"></a>
                                    </li>
                                    <li>
                                    <a href="#" class="fa fa-linkedin"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="our-team">
                                <div class="pic">
                                    <img src="https://i.ibb.co/8x9xK4H/team.jpg"/>
                                </div>
                                <div class="team-content">
                                    <h3 class="title">Markus Wagner</h3>
                                    <span class="post">Entwickler &<br/> Dolmetscher</span>
                                </div>
                                    <ul class="social">
                                        <li>
                                        <a href="#" class="fa fa-facebook"></a>
                                        </li>
                                        <li>
                                        <a href="#" class="fa fa-twitter"></a>
                                        </li>
                                        <li>
                                        <a href="#" class="fa fa-google-plus"></a>
                                        </li>
                                        <li>
                                        <a href="#" class="fa fa-linkedin"></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        )
    }
}

export default Team;


  {/* Start Single Feature
               <div className="feature" key={i}>
                   <Link to={`${val.href}`}>
                    <div className="feature-icon">
                        <img src={`${val.src}`}/>
                        <i className={`${val.icon}`}></i>
                    </div>
                    <div className="content">
                        <h4 className="title">{val.title}</h4>
                        <p className="desc">{val.desc}</p>
                    </div>
                   </Link>
               </div> */}