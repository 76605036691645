import React , { Component } from "react";
import { Link } from "react-router-dom";
import SmokeVideo from '../../public/assets/videos/smoke.mp4';

class Footer extends Component {
    render(){
        return(
            <div>
                <div className={`footer-area ${this.props.horizontal}`} id="support">
                    <div className="footer-bg">
                        <video src={SmokeVideo} autoPlay loop muted />
                    </div>
                    <div className="container mt--100">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--40">
                                    <h2 className="text-white">Kontaktiere <span className="theme-color">Uns</span></h2>
                                    <p className="text-white">Lass uns wissen, wie wir dir helfen können!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-5 col-xl-4 offset-xl-1">
                                <div className="contact-inner">
                                    <h3 className="title">Jetzt Anfragen!</h3>
                                    <form className="contact-form" action="/">
                                        <div className="input-box">
                                            <input type="text" placeholder="Name"/>
                                        </div>

                                        <div className="input-box">
                                            <input type="text" placeholder="E-mail"/>
                                        </div>

                                        <div className="input-box">
                                            <textarea placeholder="Projekt Details"></textarea>
                                        </div>

                                        <div className="input-box">
                                            <button className="submite-button" type="submit">Senden</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-2 col-xl-4 offset-xl-2 mt_md--40 mt_sm--40">
                                <div className="contact-inner">
                                    <h3 className="title">KONTAKT</h3>
                                    <div className="conatct-info">
                                        <a href="tel:+436644477945">
                                            <div className="single-contact-info">
                                                <div className="contact-icon">
                                                    <i className="zmdi zmdi-phone"></i>
                                                </div>
                                                <div className="contact-text">
                                                    <span>+43 664 / 44 77 945</span>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="mailto:office@mediaface.at">
                                            <div className="single-contact-info">
                                                <div className="contact-icon">
                                                    <i className="zmdi zmdi-globe-alt"></i>
                                                </div>
                                                <div className="contact-text">
                                                    <span>office@mediaface.at</span>
                                                </div>
                                            </div>
                                        </a>
                                        <a target="_blank" href="https://www.google.com/maps/dir/47.041086,15.4432368/mediaface/@47.0439022,15.4411129,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x476e4b854618a6a9:0x5af92c504038dc6f!2m2!1d15.4477023!2d47.0458162">
                                            <div className="single-contact-info">
                                                <div className="contact-icon">
                                                    <i className="zmdi zmdi-pin"></i>
                                                </div>
                                                <div className="contact-text-adress">
                                                    <span>Dipl. FW Marc Ettl<br/>Kasernstraße 86b<br/>8041 Graz, Austria</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row">
                            <div className="col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                                <div className="newsletter text-center">
                                    <h3 className="text-white title">SUBSCRIBE FOR OUR NEWSLETTER</h3>
                                    <p className="text-white">There are many variations of passages of Lorem Ipsum available,<br />
                                        but the majorityhave alteration in some form, by injected humour,available</p>
                                    <form action="/" method="post" id="newsletter">
                                        <div className="newsletter-content">
                                            <input type="text" name="email" placeholder="Enter your Email address" />
                                            <button type="submit" className="button"><span>Subscribe</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>*/}
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-xl-4 offset-xl-4">
                                <div className="footer-links text-center">
                                    <a href="/"><i className="zmdi zmdi-facebook"></i></a>
                                    <a href="/"><i className="zmdi zmdi-instagram"></i></a>
                                    <a href="/"><i className="zmdi zmdi-youtube"></i></a>
                                </div>
                                <div className="footer-text text-center">
                                    <span>Copyright © 2020 <a href="/">MediaFace</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tap-top">
                    <div>
                        <i className="zmdi zmdi-long-arrow-up"></i>
                    </div>
                </div>
            </div>
        )
    }
}
export default Footer







