// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

// Create Import File
import './index.scss';
import HomeOlive from './HomeOlive';

import BlogDetails from './blog/BlogDetails';
import GrafikTexte from './blog/GrafikTexte';
import OnlineMarketing from './blog/OnlineMarketing';
import VideoProduct from './blog/VideoProduct';
import WebApp from './blog/WebApp';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './components/ScrollToTop';

import $ from 'jquery';

const ReloadScript = () => {
    const head= document.getElementsByTagName('head')[0];
    const script= document.createElement('script');
    script.src = "/assets/js/plugins.js";
    head.appendChild(script);
    return null;
};

window.onpopstate = ReloadScript;

class Root extends Component{
    render(){
        return(
            <ParallaxProvider>
                <BrowserRouter basename={'/'}>
                    <ScrollToTop>
                        <Switch>
                            <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeOlive}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/designkonzept`} component={BlogDetails}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/grafiktexte`} component={GrafikTexte}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/onlinemarketing`} component={OnlineMarketing}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/videoproduct`} component={VideoProduct}/>
                            <Route exact path={`${process.env.PUBLIC_URL}/webapp`} component={WebApp}/>
                        </Switch>
                    </ScrollToTop>
                </BrowserRouter>
        </ParallaxProvider>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();


